import React, { FC } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';

import ErrorPage from 'gatsby-theme-husky/src/templates/ErrorPage/ErrorPage';

interface IPropsError404Page extends PageProps {}
const NotFoundPage: FC<IPropsError404Page> = ({ pageContext }) => {
  const umbraco404Page = useStaticQuery(graphql`
    {
      page: allUmbracoError404(filter: { lang: { eq: "da-dk" } }) {
        nodes {
          seoMetaTitle
          seoMetaDescription
          seoMetaKeywords
          seoExternalHreflangs {
            key
            value
          }
          alternateUrls {
            lang
            url
            path
          }
          background {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
          troubleshootingGuide
          link
          titleAndDescription
          id
          lang
          searchPlaceholder
          searchTitle
          body {
            ... on TInfoBanner {
              ...FragmentInfoBanner
            }
            ... on TSignupPlaceholder {
              ...FragmentSignUp
            }
          }
          defaultCompositions {
            footer {
              ...FragmentFooter
            }
            header {
              ...FragmentHeader
            }
            siteSettings {
              ...FragmentSiteSettingsComposition
            }
            brandSettings {
              ...FragmentBrandSettingsComposition
            }
            singupFormBaner {
              ...FragmentSignUpBanner
            }
            waiSettings {
              skipText
            }
          }
        }
      }
    }
  `);

  return <ErrorPage {...umbraco404Page} pageContext={pageContext} />;
};

export default NotFoundPage;
